<template>
  <Layout>
    <v-row class="profile-container">
      <div class="overlay">
        <v-card class="mx-auto mt-10" width="350" outlined>
          <v-card-title>
            <v-avatar class="profile" color="grey" size="164" tile>{{currentUser.displayName}}</v-avatar>
          </v-card-title>
          <v-card-subtitle>
            <v-chip v-if="currentUser.claims.admin" class="ma-2" color="orange" text-color="white">
              Admin
              <v-icon right>mdi-star</v-icon>
            </v-chip>
            <v-chip v-else class="ma-2" color="blue" text-color="white">
              Editor
              <v-icon right>mdi-edit</v-icon>
            </v-chip>
          </v-card-subtitle>
          <v-card-text>
            <v-text-field v-model="currentUser.displayName" readonly label="Full Name"></v-text-field>
            <v-text-field v-model="currentUser.email" readonly label="Email Address"></v-text-field>
            <v-text-field
              v-model="form.mobileNumber"
              :readonly="!isEditEnabled"
              label="Mobile Number"
              :error-messages="
                    requiredError($v.form.mobileNumber, ['required'], 'Mobile number is required')
                  "
              @change="$v.form.mobileNumber.$touch"
              @blur="$v.form.mobileNumber.$touch"
            ></v-text-field>
            <v-text-field
              v-model="form.password"
              :type="showPassword ? 'text' : 'password'"
              :readonly="!isEditEnabled"
              placeholder="************"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              label="Password"
              @click:append="showPassword = !showPassword"
            ></v-text-field>
            <v-text-field
              v-model="form.verifyPassword"
              :type="showVerifyPassword ? 'text' : 'password'"
              placeholder="************"
              :append-icon="showVerifyPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :readonly="!isEditEnabled"
              label="Verify password"
              :error-messages="verifyPasswordError"
              @change="$v.form.verifyPassword.$touch"
              @blur="$v.form.verifyPassword.$touch"
              @click:append="showVerifyPassword = !showVerifyPassword"
            ></v-text-field>
            <div class="d-flex align-center justify-center">
              <v-btn :color="btnColor" :loading="userLoading" dark @click="editBtnClick">
                {{ btnText }}
                <template v-slot:loader>
                  <v-progress-circular indeterminate color="white" width="2" size="28" />
                </template>
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </v-row>
  </Layout>
</template>
<style lang="scss">
.profile-container {
  height: 100vh;
  background: url('../../assets/profile_bg.svg') no-repeat;
  background-size: contain;
  background-position-y: center;
}

.overlay {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
}
</style>

<script>
import Layout from '@layouts/main'
import { mapActions, mapGetters } from 'vuex'
import { validateError } from '@utils/validateFields'
import { required, sameAs, requiredIf } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import EventBus from '@utils/EventBus'
import { EventBusEvents } from '@src/constants/index.js'
export default {
  pageTitle: 'My Profile',
  components: { Layout },
  mixins: [validationMixin],
  validations() {
    return {
      form: {
        mobileNumber: { required },
        verifyPassword: {
          required: requiredIf(() => this.form.password),
          ...(this.form.password && {
            sameAs: sameAs(() => this.form.password),
          }),
        },
      },
    }
  },
  data() {
    return {
      loading: false,
      form: {
        mobileNumber: '+35612345678',
        password: '',
        verifyPassword: '',
      },
      showPassword: false,
      showVerifyPassword: false,
      showAvatarPicker: false,
      isEditEnabled: false,
    }
  },

  computed: {
    ...mapGetters('user', ['currentUser', 'userLoading']),
    btnText() {
      return this.isEditEnabled ? 'Save details' : 'Edit details'
    },
    btnColor() {
      return this.isEditEnabled ? 'teal lighten-1' : 'amber darken-4'
    },
    verifyPasswordError() {
      if (!this.$v.form.verifyPassword.$dirty) {
        return []
      }
      if (!this.$v.form.verifyPassword.required) {
        return ['Verify password is required']
      }
      if (this.form.password && !this.$v.form.verifyPassword.sameAs) {
        return ['Verify password should be the same as password']
      }
      return []
    },
  },
  mounted() {
    this.form.mobileNumber = this.$store.getters['user/currentUser'].phoneNumber
  },
  methods: {
    ...mapActions('user', ['editCurrentUser', 'reauthenticateWithCredentials']),
    openAvatarPicker() {
      this.showAvatarPicker = true
    },
    requiredError(field, requirements, error) {
      return validateError(field, requirements, error)
    },
    async editBtnClick() {
      if (!this.isEditEnabled) {
        this.isEditEnabled = true
        return
      }

      this.$v.$touch()

      if (!this.$v.$invalid) {
        try {
          let user = await this.editCurrentUser({
            mobileNumber: this.form.mobileNumber,
            password: this.form.password,
          })

          // the password have been changed
          if (this.form.password) {
            const { email } = user
            user = await this.reauthenticateWithCredentials({
              email,
              password: this.form.password,
            })
          }

          const { phoneNumber } = user
          this.form.mobileNumber = phoneNumber
          this.form.password = ''
          this.form.verifyPassword = ''
          this.isEditEnabled = false
          EventBus.$emit(
            EventBusEvents.SNACKBAR_SUCCESS,
            {
              message: 'Details updated successfully!',
            },
            this
          )
        } catch (_) {}
      }
    },
    selectAvatar(avatar) {
      this.form.avatar = avatar
    },
  },
}
</script>